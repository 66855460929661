.contact-content {
  margin: 32px 128px 96px;
}

.contact-end {
  padding: 64px;
  background-color: #f1d5f6;
}

.contact-end-container {
  display: flex;
  flex-flow: row nowrap;
}

.contact-link-container {
  text-align: left;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-content: center;
}

.contact-link-info {
  padding-left: 16px;
}

.contact-link-info:hover {
  color: #9c27b0;
}

.contact-button {
  margin-left: 64px;
  width: 200px;
  font-weight: 600;
}

@media screen and (max-width: 960px) {
  .contact-content {
    margin: 64px 32px;
  }
}

@media screen and (max-width: 600px) {
  .contact-end {
    padding: 32px;
  }

  .contact-end-container {
    display: flex;
    flex-flow: column nowrap;
  }

  .contact-content {
    margin: 0 24px 40px;
  }

  .contact-content h6 {
    text-align: center;
  }

  .contact-content-text {
    text-align: left;
  }

  .contact-end-subcontainer-small {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
  }
}
