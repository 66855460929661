.about-section {
  text-align: center;
  padding-top: 112px;
}

.about-me-subtitle {
  padding-top: 16px;
  letter-spacing: 5px;
  padding-bottom: 64px;
}

.pic-item-small {
  width: 160px;
  height: 160px;
}

.about-me-intro {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}

.about-me-intro h6 {
  width: 800px;
}

.button-groups {
  margin-top: 32px;
}

.divider-top {
  margin-top: 32px;
}

@media screen and (max-width: 600px) {
  .about-me-intro {
    margin: 32px 24px 0;
  }
}
