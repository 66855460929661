.experience-timeline {
  margin: 0 64px;
}

.timeline-content-right {
  text-align: left;
}

.location-name {
  padding-bottom: 8px;
}

@media screen and (max-width: 960px) {
  .experience-timeline {
    text-align: left;
  }

  .TimelineItem.MuiTimelineItem-missingOppositeContent::before {
    content: none;
  }

  .timeline-content-right {
    text-align: left;
  }
}

@media screen and (max-width: 600px) {
  .experience-timeline {
    margin: 0;
  }
}
