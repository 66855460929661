.project-card-container {
  width: 950px;
  margin-left: calc((100% - 950px) / 2);
}

.card-container {
  display: flex;
  flex-flow: row nowrap;
  text-align: left;
  margin-bottom: 24px;
}

.card-content-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
}

.card-tech-stack {
  margin-top: 6px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.card-tech-stack-item {
  margin-right: 2px;
  margin-bottom: 2px;
}

.card-project-intro {
  margin-top: 8px;
}

.card-button-groups {
  margin-top: 6px;
}

@media screen and (max-width: 960px) {
  .project-card-container {
    width: 590px;
    margin-left: calc((100% - 590px) / 2);
  }
}

@media screen and (max-width: 600px) {
  .project-card-container {
    width: 310px;
    margin-left: calc((100% - 310px) / 2);
  }

  .card-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
    margin-bottom: 24px;
  }

  .card-content-container.MuiCardContent-root {
    padding: 8px;
  }

  .card-tech-stack {
    justify-content: center;
  }
}
